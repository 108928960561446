import { MainHelpers } from "./MainHelper";
import { ReactProducer } from "./Ui/ReactProducer";
import { ReactConsumer } from "./Ui/ReactConsumer";
import { Util } from "./common/Util";
import { settings, Logic } from "./Logic/Logic";
import { Logger } from "./Logic/Logger";
import { GameType, PerformanceSetting, GameLength, SkinType } from "./common/Definitions";
import { RtcLogic } from "./Rtc/RtcLogic";
import { ErrorHandler, Errors } from "./LogicImplementation/ErrorHandler";
import { SockServLogMessage } from "./ServerWebSocket/base/ServerSocketLogicBase";
import { ServerSocketLogic } from "./ServerWebSocket/ServerSocketLogic";

function queryDeviceId() {
  let deviceId = Util.getUrlParameter(window.location.href, "deviceid");
  if (!deviceId) {
    // TODO give Error
    deviceId = "00:1E:06:43:81:DB";
    // ErrorHandler.instance.normalErrorHandler(Errors.NO_URL_DEV, true);
    // return;
  }
  return deviceId;
}

export class App {
  public constructor() {
    //Logger.info("Start App: " + new Date(webpackConfig.buildTime).toUTCString());

    if (Logic.introMusic) Logic.introMusic.pause();

    window.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
      let errorMessage = "Unhandled exception (src: " + source + "(" + lineno + ")" + event.toString() + error + "\n";
      if (error) {
        errorMessage += error.stack;
      }

      Logger.error(errorMessage);

      const errorObj = Errors.RUNTIM_ERROR;

      ErrorHandler.instance.normalErrorHandler(errorObj, true);

      const logMessage = new SockServLogMessage(Errors.RUNTIM_ERROR.code, errorMessage);
      ServerSocketLogic.instance.sendLogRequest(logMessage).catch((errorData) => {
        Logger.error("Send log Error:" + JSON.stringify(errorData));
      });
    };

    const id = Util.getUrlParameter(window.location.href, "id"); // ? queryId : Math.random().toString(36).substring(7);
    const roomId = Util.getUrlParameter(window.location.href, "roomId");
    const type = Util.getUrlParameter(window.location.href, "type"); // producer // consumer
    const dpr = window.devicePixelRatio;
    settings.screen.width = Util.getUrlParameterInt(window.location.href, "width", window.outerWidth * dpr);
    settings.showUI = Util.getUrlParameterBool(window.location.href, "showUI", false);
    settings.devUser = Util.getUrlParameter(window.location.href, "devUser");
    settings.forceDummyLogic = Util.getUrlParameterBool(window.location.href, "forceDummyLogic", false);
    settings.useCache = Util.getUrlParameterBool(window.location.href, "useCache", false);
    settings.forceGameType = Util.getUrlParameter(window.location.href, "gameType") as GameType;
    const gameSkinParameter = Util.getUrlParameter(window.location.href, "gameSkin");
    if (gameSkinParameter) {
      settings.forceGameSkin = parseInt(gameSkinParameter) as SkinType;
    }
    settings.forceGameLength = Util.getUrlParameterIntOrUndefined(window.location.href, "gameLength") as GameLength;
    settings.showBonus = Util.getUrlParameterBool(window.location.href, "showBonus", false);
    settings.forcePerformance = Util.getUrlParameter(window.location.href, "performance") as PerformanceSetting;
    settings.forceReloadContent = Util.getUrlParameterBool(window.location.href, "forceReloadContent", false);
    settings.startImmediately = Util.getUrlParameterBool(window.location.href, "startImmediately", false);
    settings.playbackSpeed = Util.getUrlParameterFloat(window.location.href, "speed", 1.0);
    settings.showText = Util.getUrlParameterBool(window.location.href, "showText", false);
    settings.debug = Util.getUrlParameterBool(window.location.href, "showDebug", false);
    settings.stopAfterSeek = Util.getUrlParameterBool(window.location.href, "stopAfterSeek", false);
    settings.showDebugTextColor = Util.getUrlParameterBool(window.location.href, "showDebugTextColor", false);
    settings.videoStartTime = Util.getUrlParameterFloat(window.location.href, "videoStartTime", 0);
    settings.sdCardPath = Util.getUrlParameter(window.location.href, "sdCardPath");
    settings.syncStartTimeParameter = Util.getUrlParameterBool(window.location.href, "syncStartTimeParameter", false);
    settings.languageId = Util.getUrlParameter(window.location.href, "languageId");
    settings.deviceId = queryDeviceId();

    settings.screen.height = (settings.screen.width * 720) / 1280;
    settings.screen.width = Math.round(settings.screen.width);
    settings.screen.height = Math.round(settings.screen.height);
    settings.viewport.width = settings.screen.width / dpr;
    settings.viewport.height = settings.screen.height / dpr;
    settings.scaleFactor = settings.screen.width / 1280;

    // Enable dummy logic for testing
    /* settings.forceDummyLogic = true;
    settings.forceGameType = "dog6";
    settings.forceGameSkin = 1;*/

    const deviceId = queryDeviceId();

   /* if (deviceId === "00:1E:06:43:81:DA") {
      settings.forceGameType = "dog6";
      settings.forceGameSkin = 1;
    } else*/ if (deviceId === "horse") {
      settings.forceDummyLogic = true;
      settings.forceGameType = "horse";
      settings.forceGameSkin = 1;
    } else if (deviceId === "roulette") {
      settings.forceDummyLogic = true;
      settings.forceGameType = "roulette";
      settings.forceGameSkin = 1;
    }
    Logger.info("HostName: " + window.location.hostname + " ID: " + id);

    Logger.info("Settings: " + JSON.stringify(settings));

    let shouldUseLocalAssetServer = Util.getUrlParameterBool(window.location.href, "localAssetServer", false);
    let localAssetServerPort = Util.getUrlParameterInt(window.location.href, "localAssetServerPort", 8080);

    if (shouldUseLocalAssetServer) {
      let localAssetServerUrl = "http://localhost:" + localAssetServerPort + "/";

      settings.sdCardPath = localAssetServerUrl;
    }

    if (id) {
      RtcLogic.instance.init(id, roomId, type, { deviceId: settings.deviceId });
    }

    const f = async () => {
      try {
        if (id) {
          await RtcLogic.instance.connectRtcClient();
        }

        if (RtcLogic.instance.isProducer() || RtcLogic.instance.isPlayer()) MainHelpers.render(ReactProducer);
        else MainHelpers.render(ReactConsumer);
      } catch (e) {
        Logger.error("App constructor: " + e);
      }
    };
    f();
  }

  public exit(): void { }
}
